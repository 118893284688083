import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 612.000000 412.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,412.000000) scale(0.100000,-0.100000)">
<path d="M2410 3090 c-266 -50 -500 -311 -610 -680 -64 -217 -84 -473 -56
-709 17 -145 32 -197 19 -66 -4 44 -7 141 -7 215 5 413 174 794 427 960 169
111 375 111 547 -1 l61 -39 120 0 120 0 -19 28 c-87 119 -222 227 -339 267
-79 28 -193 39 -263 25z"/>
<path d="M2800 2701 c0 -5 44 -132 99 -282 54 -151 110 -305 124 -344 29 -83
26 -81 192 -77 l112 3 -89 217 c-49 119 -110 269 -134 332 -25 63 -49 125 -54
138 -9 21 -15 22 -130 22 -66 0 -120 -4 -120 -9z"/>
<path d="M3675 2683 c-7 -30 -228 -580 -250 -623 -7 -14 -16 -35 -19 -47 l-7
-23 139 0 140 0 41 113 c22 61 81 223 129 360 l89 247 -127 0 -128 0 -7 -27z"/>
<path d="M2766 2514 c-4 -10 -45 -125 -91 -255 -92 -259 -115 -319 -122 -319
-20 0 -349 -43 -352 -46 -2 -2 250 -4 560 -3 310 0 557 3 549 7 -8 3 -87 22
-174 41 l-160 36 -98 270 c-54 149 -99 274 -101 279 -2 6 -7 1 -11 -10z"/>
<path d="M4341 2184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4355 2116 c27 -270 7 -480 -66 -700 -86 -258 -232 -447 -415 -538
-81 -40 -93 -43 -185 -46 -119 -5 -203 17 -297 78 l-63 40 -120 0 -121 0 50
-62 c94 -119 228 -213 352 -249 68 -19 215 -16 280 5 245 81 452 332 549 666
42 143 61 261 68 415 5 127 -8 333 -27 405 -9 31 -9 30 -5 -14z"/>
<path d="M2825 1825 c6 -2 77 -20 159 -39 82 -19 153 -41 157 -48 4 -7 20 -46
35 -88 14 -41 55 -154 89 -250 35 -96 70 -199 80 -227 9 -29 18 -53 20 -53 1
0 24 60 50 133 26 72 77 215 114 316 l66 184 150 33 c83 18 159 35 170 37 11
2 -232 5 -540 5 -308 0 -555 -1 -550 -3z"/>
<path d="M2792 1713 c3 -10 58 -146 123 -302 64 -157 126 -309 137 -338 l19
-53 124 0 123 0 -29 83 c-17 45 -74 204 -127 352 l-97 270 -139 3 c-129 2
-138 1 -134 -15z"/>
<path d="M2473 1688 c-6 -7 -13 -22 -16 -33 -3 -11 -24 -72 -47 -135 -23 -63
-68 -189 -100 -280 -32 -91 -63 -177 -69 -192 l-11 -28 98 0 98 0 46 118 c26
64 83 207 128 317 45 110 86 210 91 223 9 22 8 22 -100 22 -75 0 -112 -4 -118
-12z"/>
<path d="M1771 1534 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
